<template>
  <v-container>
    <v-card class="px-5 py-3">
      <v-row>
        <v-col cols="12">
          <h2 class="align-center">Доп. тариф</h2>
        </v-col>
        <v-col cols="6" md="4">
          <v-select
            v-model="category"
            label="Тариф"
            :items="categoryList"
            :loading="loading"
            :disabled="loading"
          />
        </v-col>
        <v-col cols="6" md="4">
          <v-menu
            v-model="menu"
            ref="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Период"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                readonly
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              range
              no-title
              :first-day-of-week="1"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                Закрыть
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(dates)"
                :disabled="disabledBtn"
              >
                Сохранить
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" md="4">
          <v-text-field v-model.number="base_price" label="Цена" />
        </v-col>
        <v-col cols="6" md="4">
          <v-text-field
            v-model.number="addition_price"
            label="Цена за доп. место"
          />
        </v-col>
        <v-col cols="6" md="4">
          <v-text-field
            v-model.number="child_price"
            label="Цена за детское место"
          />
        </v-col>
        <v-col cols="6" md="4">
          <v-text-field
            v-model.number="single_reserve_price"
            label="Цена за одно место"
          />
        </v-col>
        <v-col cols="6" md="4">
          <v-text-field v-model.number="time" label="Часовая цена" />
        </v-col>
        <v-col cols="6" md="4">
          <v-text-field
            v-model.number="time_adult"
            label="Часовая цена за взрослое место"
          />
        </v-col>
        <v-col cols="6" md="4">
          <v-text-field
            v-model.number="time_child"
            label="Часовая цена за детское место"
          />
        </v-col>
        <v-col cols="6" md="4">
          <v-text-field v-model.number="koika" label="Цена за койку" />
        </v-col>
        <v-col cols="6" md="4">
          <v-text-field
            v-model.number="child_without_place"
            label="Цена для детей без места"
          />
        </v-col>
      </v-row>
      <v-col cols="8" class="pl-0">
        <v-btn @click="save" color="primary"> Сохранить </v-btn>
        <v-btn @click="quit" color="primary" outlined> Отмена </v-btn>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
import user from "../../../store/auth";
import moment from "moment";
import toast from "../../../plugins/toast";
import { TariffsApiUrls } from "@/services/accommodationRequests/tariffs.api.js";

export default {
  name: "AdditionalTariffs",
  data: () => ({
    disabledBtn: false,
    child_price: 0,
    menu: false,
    dates: [],
    id: null,
    dataSet: null,
    date: new Date(),
    modal: false,
    categoryList: [],
    category: null,
    base_price: 0,
    single_price: 0,
    addition_price: 0,
    single_reserve_price: 0,
    loading: false,
    time: 0,
    time_adult: 0,
    time_child: 0,
    child_without_place: 0,
    koika: 0
  }),
  watch: {
    dates() {
      if (this.dates[0] > this.dates[1]) {
        this.dates.reverse();
      }
    }
  },
  computed: {
    dateRangeText() {
      var dates = [
        moment(this.dates[0]).format("DD.MM.YYYY"),
        moment(this.dates[1]).format("DD.MM.YYYY")
      ];
      return dates.join(" ~ ");
    }
  },
  methods: {
    async save() {
      const tariffInfo = {
        tariff: this.category,
        start_date: moment(this.dates[0]).format("YYYY-MM-DD"),
        end_date: moment(this.dates[1]).format("YYYY-MM-DD"),
        base_price: this.base_price,
        single_price: this.single_reserve_price,
        addition_price: this.addition_price,
        child_price: this.child_price,
        time: this.time,
        time_adult: this.time_adult,
        time_child: this.time_child,
        koika: this.koika,
        child_without_place: this.child_without_place
      };
      this.disabledBtn = true;
      if (this.$route.params.id) {
        try {
          await TariffsApiUrls.setAdditional(this.$route.params.id, tariffInfo);
          this.disabledBtn = false;
          toast.success("Успешно изменено!");
          this.$router.push("/additional-tariffs");
        } catch (e) {
          this.disabledBtn = false;
        }
      } else {
        try {
          await TariffsApiUrls.createAdditional(tariffInfo);
          toast.success("Успешно создано!");
          this.disabledBtn = false;
          this.$router.push("/additional-tariffs");
        } catch (e) {
          this.disabledBtn = false;
        }
      }
    },
    quit() {
      this.$router.push("/additional-tariffs");
    }
  },
  async created() {
    this.loading = true;
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      let response = await TariffsApiUrls.getAdditional(this.id);
      this.category = response.tariff;
      this.base_price = response.base_price;
      this.addition_price = response.addition_price;
      this.single_reserve_price = response.single_price;
      this.child_price = response.child_price;
      this.time = response.time;
      this.time_adult = response.time_adult;
      this.time_child = response.time_child;
      this.child_without_place = response.child_without_place;
      this.koika = response.koika;
      this.dates = [
        moment(Number(response.start_date) * 1000).format("YYYY-MM-DD"),
        moment(Number(response.end_date) * 1000).format("YYYY-MM-DD")
      ];
    }
    let response = await TariffsApiUrls.getTariffs();
    for (const index in response.results) {
      const list = {
        text: null,
        value: null
      };
      list.text = response.results[index].name;
      list.value = response.results[index].id;
      this.categoryList.push(list);
    }
    this.loading = false;
  }
};
</script>

<style scoped></style>
